<template>
  <div class="detail">
    <div class="nav">
      <i class="arrow"
         @click="$router.back(-1)"></i>
      <h2>{{this.detail.physique_name}}体质</h2>
    </div>

    <div class="attr">
      <div class="item"
           v-for="(item,index) in detail.attr"
           :key="index">
        <div class="key">{{item.key}}</div>
        <div class="value">{{item.value}}</div>
      </div>
    </div>

    <div class="line"></div>

    <div class="questionContainer">
      <div class="title">测试问题</div>
      <div class="questionList">
        <div class="question"
             v-for="(item,index) in detail.questionList"
             :key="index">
          {{item.question_text}}</div>
      </div>
    </div>

    <div class="start"
         @click="startAction">
      开始体质测试
    </div>

  </div>
</template>

<script>
import { http } from "../config";
export default {
  name: 'medicaldetail',
  data () {
    return {
      detail: {}
    };
  },
  mounted () {
    this.get()
  },
  methods: {
    get () {
      let query = this.$route.query;
      let url = `/cmpa/physique/` + query.id;
      http.get(url).then(res => {
        this.detail = res.data
      });
    },
    startAction () {
      let query = this.$route.query;
      this.$router.push({
        path: "/medicalbegin",
        query
      });
    }
  }
}
</script>

<style lang="scss" scoped>
i.arrow {
  margin-top: 0.1rem;
  background: url('../assets/img/medical/arrowGreen.png') no-repeat;
  background-size: 100% 100%;
}
.detail {
  width: 7.5rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .nav {
    border: 1px solid rgba(233, 233, 233, 1);
    width: 7.5rem;
    margin: 0 0 0.2rem 0;
    .arrow {
      top: 0.5rem;
    }
    padding-bottom: 0.2rem;
    position: relative;
    overflow: hidden;
    h2 {
      font-size: 0.4rem;
      font-weight: 500;
      line-height: 0.56rem;
      margin-top: 0.5rem;
    }
  }

  .attr {
    padding: 0 0.4rem 0 0.4rem;
    text-align: left;
    .item {
      .key {
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(69, 69, 69, 1);
        padding: 0.05rem 0;
      }
      .value {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(103, 103, 103, 1);
        padding: 0.05rem 0 0.25rem 0;
      }
    }
  }

  .line {
    height: 0.3rem;
    background: #f9f9f9;
  }

  .questionContainer {
    text-align: left;
    .title {
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(69, 69, 69, 1);
      padding-top: 0.4rem;
      &::before {
        content: '';
        width: 0.26rem;
        height: 0.26rem;
        background: red;
        margin-left: 0.4rem;
      }
    }
    .questionList {
      padding: 0.05rem 0.4rem 0.96rem 0.4rem;
      .question {
        padding: 0.05rem 0;
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(103, 103, 103, 1);
      }
    }
  }

  .start {
    margin: 0 0.6rem 0.8rem 0.6rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: rgba(1, 201, 177, 1);
    border-radius: 0.2rem;
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
